<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn
      ><v-btn color="primary" @click="createNew()" title="Создать элемент" v-if="getAccess('project.create')"
        ><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn
      ></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :api="url"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/projectDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.projects.list,
      url: "/mechti/design-projects",
      defaults: {
        sort: { key: "id", order: "DESC" },
        //   filters: { status: [0, 1, 2, -1] },
        paramName: "projects",
      },
    };
  },
  created() {
    this.$root.title = "Дизайн Проекты";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.$router.push({ name: "projects_view", params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
